<template>
  <div class="outside">
    <div class="top-banner">
      <img src="../../assets/img/digitize/storeroom-banner.jpg" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">系统介绍</div>
        <div class="titleEng">System Introduction</div>
        <el-row>
          <el-col :span="24">
            <div class="content" v-for="(item,index) in introData" :key="index">
              {{item.value}}
            </div>
          </el-col>
        </el-row>

        <div class="photos">
          <el-row>
            <el-col :span="24">
              <img src="../../assets/img/digitize/storeroom-intro.png" alt=""/>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="repair_case">
      <div class="edition_heart">
        <div class="title">智能库房管理系统</div>
        <div class="titleEng">Intelligent Warehouse Management System</div>
        <div class="photos storeroom-img-contain">
          <el-row>
            <el-col :span="24">
              <img src="../../assets/img/digitize/storeroom-systerm.png" alt=""/>
              <div class="photos-text">智能化库房管理，清晰记录出入库</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Storeroom',
  data(){
    return{
      introData:[{
        value:"文物作为前史留存下来最为珍贵的遗物，具有非常高的科学价值和艺术价值，博物馆的存在便是为了保存这些珍贵的前史文化遗产，所以对博物馆的建造必定要重视品质问题。仓库办理工作，责任重大。也随着现代考古发现的文物，越来越多，仓库办理使命也变得越来越繁重，在当前局势下，如何做好博物馆的文物仓库办理工作，文物库房管理就成了急需解决的一个问题。"
      },{
        value: "因此我司利用电子化智能技术对博物馆藏品进行管理和保护，针对博物馆定制开发的藏品管理系统，有效解决当下文物库房管理的问题。藏品管理系统其中包括藏品的存放、检索、移动等功能，确保藏品的安全和完整性。"
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.photos img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.storeroom-img-contain {
  margin-top: 60px;
}

.photos-text {
  font-size: 20px;
  color: #071637;
  margin-top: 20px;
  text-align: center;
}

.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  img{
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}


@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 70px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0px 0 0px;
    }

    .photos {
      padding-bottom: 95px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 125px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 87px 0 95px;
    }

    .photos {
      padding-bottom: 95px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 60px;
  }

  .introduction {
    .title {
      padding-top: 20px;
    }
  }

  .edition_heart {
    padding-top: 0px;
  }

  .repair_case {
    .title {
      padding-top: 30px;
      font-size: 24px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 90%;
    }

    .photos {
      padding-bottom: 10px;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0;
    }
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;

    .title {
      padding-top: 80px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .content {
      margin-top: 24px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      margin: 30px 0 0;
    }
  }

  .repair_case {
    width: 100%;
    height: auto;
    color: #333;
    position: relative;

    .title {
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .photos {
      --swiper-navigation-color: #fff;
      /* 单独设置按钮颜色 */
      --swiper-navigation-size: 16px;
      /* 设置按钮大小 */
      position: relative;

      .swiper-button-next {
        position: absolute;
      }

      .swiper-button-prev {
        position: absolute;
      }

      .swiper {
        width: 100%;
        height: auto;
        padding: 45px 0px 10px;

        .swiper_slide {
          // height: 270px;
          display: flex;
          align-items: center;
        }
      }

      .repair_case_photos {
        width: 100%;
        cursor: pointer;

        img {
          width: 95%;
          height: auto;
          border: 5px solid rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  // 版心
  .edition_heart {
    margin: 0px auto;
    position: relative;
  }
}
</style>
